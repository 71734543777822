import React, { useState, useContext } from "react"
import { navigate } from "gatsby"
import { Helmet } from "react-helmet"
import { useQuery, queryCache } from "react-query"

// Layout
import KnockOut from "../components/layout/KnockOut"
import PodForm from "../components/forms/PodForm"

// Utility
import { Pods } from "../config/db"
import { podFormSchema } from "../helpers/validation"
import withWindow from "../helpers/withWindow"
import { AuthContext } from "../context/AuthContext"

const EditPage = () => {
  const { isAuth } = useContext(AuthContext)
  if (!isAuth) navigate("/signin?mode=manage")
  const [status, setStatus] = useState("Loading...")

  const { data, isLoading, isError, refetch, isFetching } = useQuery(
    "Edit - fetchPod",
    async () => {
      const data = queryCache.getQueryData("AuthContext - hasPod")
      const pod = await Pods.fetchById(data.id)
      setStatus(pod.status)
      return pod
    }
  )

  const handleSubmit = async pod => {
    await Pods.update(pod, data.id)
    navigate(`/inform?mode=edit&id=${pod.id}`)
  }
  if (isError) {
    navigate(`/inform?mode=missing&id=none`, { replace: true })
  }

  const deletePod = async () => {
    if (
      confirm(
        "Are you sure you want to delete your pod? This action can't be undone"
      )
    ) {
      Pods.delete(data.id)
      queryCache.invalidateQueries("AuthContext - hasPod", {
        refetchInactive: true,
      })
      navigate(`/inform?mode=delete`)
    }
  }

  const toggleStatus = async () => {
    Pods.update(
      { ...data, status: status === "Open" ? "Closed" : "Open" },
      data.id
    )
    await refetch()
  }

  return (
    <>
      <Helmet>
        <title>Edit a Pod</title>
      </Helmet>
      {isLoading ? (
        <KnockOut />
      ) : isError ? (
        <div className="[ wrapper ]">
          <h2>There was an error getting your pod {`:(`}</h2>
          <p>Please try again later</p>
        </div>
      ) : (
        <PodForm
          editMode
          initialValues={data}
          onSubmit={handleSubmit}
          handleDelete={deletePod}
          status={status}
          isFetching={isFetching}
          toggleStatus={toggleStatus}
          validationSchema={podFormSchema(true)}
        />
      )}
    </>
  )
}

export default withWindow(EditPage)
