import React from "react"
import {
  Form,
  Fieldset,
  FormField,
  FormPicker,
  FormCheckList,
  FormTextArea,
  Button,
  AgreementFields,
} from "."

import { graphql, Link, useStaticQuery } from "gatsby"
import TagLine from "./TagLine"
import { transformStates } from "../../helpers/states"

const PodForm = ({
  initialValues,
  isFetching,
  onSubmit,
  handleDelete,
  validationSchema,
  toggleStatus,
  status = null,
  editMode = false,
}) => {
  const { podAttributes, allStates } = useStaticQuery(query)
  const states = transformStates(allStates.nodes)
  return (
    <Form
      initialValues={initialValues}
      setValues={{ fields: initialValues }}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      <div className="[ form ][ wrapper flow ] [ create ]">
        <h2>{editMode ? "Edit your pod" : "Create a pod"}</h2>
        {editMode && (
          <div className="mirror flex shadow-box">
            <p>
              Pod status: <strong>{status}</strong>
            </p>
            <div className="flow-hrz flow-space-400 hero__actions">
              <Link to={`/profile?id=${initialValues.id}`}>
                <button className="[ button ]">View my pod profile</button>
              </Link>

              <button className="[ button ]" onClick={toggleStatus}>
                {isFetching
                  ? "Updating..."
                  : status === "Open"
                  ? "Close my pod"
                  : "Open my pod"}
              </button>

              <button className="[ button red ]" onClick={handleDelete}>
                Delete my pod
              </button>
            </div>
          </div>
        )}
        <section className="shadow-box">
          <Fieldset
            heading="Pod parent info"
            content="We will not share or list your personal information"
          >
            <ul className="[ auto-grid ]">
              <FormField
                label="Pod name"
                name="podName"
                id="podName"
                autoComplete="new-password" // autoComplete="new-password" disables autocomplete in all browsers
                required
              />
              <FormField
                label="Email address"
                name="emailAddress"
                id="emailAddress"
                type="email"
                required
              />
              <FormField
                label="ZIP Code"
                name="zipCode"
                id="zipCode"
                type="text"
                inputMode="numeric"
                pattern="[0-9]*"
                required
              />
            </ul>
          </Fieldset>
        </section>

        <section className="shadow-box">
          <Fieldset heading="Class information">
            <ul className="[ auto-grid ]">
              <FormPicker
                label="Term"
                name="term"
                items={podAttributes.terms}
                required
              />
              <FormField
                label="School name"
                name="schoolName"
                autoComplete="new-password"
              />
              <FormPicker
                label="Grade"
                name="grade"
                items={podAttributes.grades}
              />
              <FormPicker
                label="Pod size"
                name="podSize"
                items={podAttributes.podSize}
                required
              />
              <FormField
                label="County"
                name="county"
                autoComplete="new-password"
                required
              />
              <FormPicker
                label="Pod location"
                name="location"
                items={podAttributes.location}
                required
              />
              <FormField
                label="City"
                name="city"
                autoComplete="new-password"
                required
              />
              <FormPicker label="State" name="state" items={states} required />
              <FormPicker
                label="Instructor"
                name="instructor"
                items={podAttributes.instructors}
                required
              />
              <FormPicker
                label="Curriculum"
                name="curriculum"
                items={podAttributes.curriculums}
                required
              />
              <FormField
                currency
                label="Cost"
                name="cost"
                inputMode="numeric"
                type="number"
                placeholder="0.00"
                step="0.01"
                min="0"
              />
              <FormPicker
                label="Payment cycle"
                name="paymentCycle"
                items={podAttributes.paymentCycle}
                required
              />
            </ul>
          </Fieldset>
        </section>

        <section className="shadow-box">
          <Fieldset
            heading="Personalization"
            content="These are things that are core to your Pod's value system."
          >
            <ul className="[ auto-grid ]">
              <FormCheckList
                title="Safety requirements"
                name="safety"
                items={podAttributes.safety}
              />
              <FormCheckList
                title="Preferences"
                name="preferences"
                items={podAttributes.preferences}
              />
              <FormCheckList
                title="Technology"
                name="technology"
                items={podAttributes.technology}
              />
            </ul>
          </Fieldset>
        </section>

        <section className="shadow-box">
          <Fieldset heading="Pod culture">
            <ul className="culture-input">
              {podAttributes.culture.map(({ value, formLabel }) => (
                <FormTextArea key={value} label={formLabel} name={value} />
              ))}
            </ul>
          </Fieldset>
        </section>

        {!editMode && (
          <>
            <AgreementFields />
            <TagLine />
          </>
        )}
        <Button
          className="[ stretch ]"
          title={editMode ? "Save changes" : "Create pod"}
        />
      </div>
    </Form>
  )
}

export default PodForm

const query = graphql`
  {
    podAttributes {
      terms
      grades
      location
      instructors
      curriculums
      podSize
      safety
      preferences
      technology
      paymentCycle
      culture {
        formLabel
        value
      }
    }
    allStates {
      nodes {
        long
        short
      }
    }
  }
`
