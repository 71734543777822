import React from "react"
import { useFormikContext } from "formik"

const TagLine = () => {
  const { values } = useFormikContext()
  if (!values["emailAddress"]) return null
  return (
    <p className="text-center">
      When you click &apos;Create pod&apos;, an email confirmation will be sent
      to <strong>{values["emailAddress"]}</strong>.
    </p>
  )
}

export default TagLine
